import sanitizeHtml from 'sanitize-html'

export const defaultSanitizeOptions = {
  allowedTags: ['b', 'i', 'em', 'strong', 'u', 'p'],
  allowedAttributes: {},
}

const sanitizeHTML = (dirty) => sanitizeHtml(dirty, defaultSanitizeOptions)

export default sanitizeHTML
