import React, { useContext, useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { FaArrowLeft, FaHome, FaSearch } from 'react-icons/fa'
import PropTypes from 'prop-types'
import TestButton from './TestButton'
import { SceneContext } from '../../context/sceneContext'
import { ScenarioContext } from '../../context/scenarioContext'
import calculateScenarioScore from '../../helpers/calculateScenarioScore'
import { CollapsibleContent, CollapsibleHeader } from '../UI/Collapsible/Collapsible'
import { getDisplayedNodeElements } from '../../helpers/elementHelper'
import NodeElementCard from './NodeElementCard'
import PointsCircle from './PointsCircle'
import SearchModal from './SearchModal'
import sanitizeHTML from '../../helpers/sanitizeHTML'

const TOOLTIP_POSITION = 'bottom'
const COLLAPSE_STATE_STORAGE_KEY = 'testingCollapseState'
const collapseState = localStorage.getItem(COLLAPSE_STATE_STORAGE_KEY)
const INITIAL_COLLAPSE_STATE = {
  scenes: true,
  elements: true,
  scores: true,
  ...(collapseState ? JSON.parse(collapseState) : {}),
}

const TestingHeader = ({ title, right, storageKey }) => {
  const [isOpen, _setIsOpen] = useState(INITIAL_COLLAPSE_STATE[storageKey])

  const setIsOpen = (nextIsOpen) => {
    const collapseState = localStorage.getItem(COLLAPSE_STATE_STORAGE_KEY)
    localStorage.setItem(
      COLLAPSE_STATE_STORAGE_KEY,
      JSON.stringify({
        ...(collapseState ? JSON.parse(collapseState) : {}),
        [storageKey]: nextIsOpen,
      })
    )
    _setIsOpen(nextIsOpen)
  }

  return (
    <CollapsibleHeader
      title={title}
      right={right !== null && <span className="font-bold">{right}</span>}
      className={`mt-2.5 p-5 bg-white/10 transition-all ${isOpen ? 'rounded-t-xl' : 'rounded-xl'}`}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  )
}

const TestingContentSection = ({ children }) => (
  <div className="bg-white/5 p-5 last:rounded-b-xl mt-0.5">{children}</div>
)

const TestingContent = ({ children, noWrapSection }) => (
  <CollapsibleContent>
    {noWrapSection ? children : <TestingContentSection>{children}</TestingContentSection>}
  </CollapsibleContent>
)

const TestingControls = () => {
  const { t } = useTranslation()
  const {
    handleSceneChange,
    scene,
    attemptState: { answers: chosenAnswers },
    previousScene,
    sceneSeries,
  } = useContext(SceneContext)
  const { scenarioDetail } = useContext(ScenarioContext)
  const [showJumpSceneModal, setShowJumpSceneModal] = useState(false)
  const asideRef = useRef()

  const restartScenario = () => window.location.reload()

  const handleJump = (sceneId) => {
    handleSceneChange(sceneId)
    setShowJumpSceneModal(false)
  }

  const handlePreviousScene = () => previousScene()
  const disableMouseWheel = (e) => e.stopPropagation()

  const renderSceneSection = () => (
    <div>
      <TestingHeader title={t('scene', 'Scene')} storageKey="scenes" right={scene.number} />
      <TestingContent>
        <header className="font-bold">{scene.name}</header>
        {scene.description && <p className="text-warp-gray" dangerouslySetInnerHTML={{
          __html: sanitizeHTML(scene.description),
        }} />}

      </TestingContent>
    </div>
  )

  const renderElementSection = () => {
    const elements = getDisplayedNodeElements(scene)

    return (
      <div>
        <TestingHeader title={t('elements', 'Elements')} storageKey="elements" />
        {elements.length ? (
          <TestingContent noWrapSection>
            {elements.map((el) => (
              <TestingContentSection key={el.id ?? el.groupUuid}>
                <NodeElementCard element={el} answerElements={el.answerElements} index={el.index} />
              </TestingContentSection>
            ))}
          </TestingContent>
        ) : (
          <TestingContent>
            <div className="italic text-warp-gray text-sm">
              This scene does not contain any elements.
            </div>
          </TestingContent>
        )}
      </div>
    )
  }

  const renderScoreSection = () => {
    const { kind: scoringKind } = scenarioDetail.scoringSystem
    const scoringIsDisabled = () => !scoringKind || scoringKind === 'DISABLED'
    if (scoringIsDisabled()) return null

    const allElements = scenarioDetail.scenes.map((sc) => getDisplayedNodeElements(sc)).flat()
    const score = calculateScenarioScore(scenarioDetail, chosenAnswers)

    return (
      <div>
        <TestingHeader
          title={t('previous_scenes_points', 'Previous scenes and points')}
          storageKey="scores"
        />
        <TestingContent noWrapSection>
          <TestingContentSection>
            <div className="flex items-center justify-between text-sm">
              <span className="font-bold">{t('scoring_system', 'Scoring system')}</span>
              <span className="text-warp-gray">
                {scoringKind === 'SUBTRACT'
                  ? t('subtracting_kind', 'Subtracting points')
                  : t('adding_kind', 'Adding points')}
              </span>
            </div>
            <div className="flex items-center justify-between text-sm mt-2">
              <span className="font-bold">{t('total_points', 'Total points')}</span>
              <span className="text-warp-gray">{score}</span>
            </div>
          </TestingContentSection>

          {chosenAnswers.map((answer) => {
            let answerElement
            const questionElement = allElements.find((el) => {
              answerElement =
                el.id === answer ? el : el.answerElements.find((ae) => answer === ae.id)
              return answerElement
            })
            const questionScene = scenarioDetail.scenes.find((sc) =>
              sc.elements.find((el) => el.id === answer)
            )
            const points = answerElement.points

            return (
              <TestingContentSection key={answer}>
                <div className="flex items-center justify-between text-sm">
                  <div>
                    <div className="font-bold">
                      {questionScene.number}. {questionScene.name}
                    </div>
                    <div className="text-warp-gray mt-1">
                      {answerElement?.letterLabel ?? questionElement.letterLabel}.{' '}
                      {answerElement.label}
                    </div>
                  </div>
                  <div className="min-w-50">
                    <PointsCircle points={points} />
                  </div>
                </div>
              </TestingContentSection>
            )
          })}
        </TestingContent>
      </div>
    )
  }

  useEffect(() => {
    asideRef.current.addEventListener('wheel', disableMouseWheel)
  }, [])

  return (
    <>
      <aside
        className="fixed top-2.5 left-2.5 rounded-xl bg-warp-dark-light/90 max-h-full h-auto
      min-w-[320px] w-[400px] max-w-[25%] z-10 p-[30px] color-warp-dark-light overflow-auto"
        ref={asideRef}
        style={{ direction: 'rtl' }}
      >
        <div style={{ direction: 'ltr' }}>
          <header className="flex items-center justify-between pb-5">
            <button
              type="button"
              className="h-12 rounded-3xl px-5 text-lg font-bold hover:cursor-pointer text-black bg-warp-yellow"
            >
              {t('test', 'Test')}
            </button>

            <div className="flex items-center">
              <TestButton
                title={t('restart_scenario', 'Restart scenario')}
                onClick={restartScenario}
                tooltipPosition={TOOLTIP_POSITION}
              >
                <FaHome className="text-xl" />
              </TestButton>

              <TestButton
                className={sceneSeries.length === 1 ? 'pointer-events-none !opacity-30' : ''}
                title={t('previous_scene', 'Previous scene')}
                onClick={handlePreviousScene}
                tooltipPosition={TOOLTIP_POSITION}
              >
                <FaArrowLeft className="text-xl" />
              </TestButton>

              <TestButton
                title={t('search', 'Search')}
                onClick={() => setShowJumpSceneModal(true)}
                tooltipPosition={TOOLTIP_POSITION}
                className={sceneSeries.length === 0 ? 'pointer-events-none !opacity-30' : ''}
              >
                <FaSearch className="text-xl" />
              </TestButton>
            </div>
          </header>
          <div className="text-white">
            {renderSceneSection()}
            {renderElementSection()}
            {renderScoreSection()}
          </div>
        </div>
      </aside>

      {showJumpSceneModal && (
        <SearchModal
          show={showJumpSceneModal}
          onClose={() => setShowJumpSceneModal(false)}
          onChange={handleJump}
        />
      )}
    </>
  )
}

TestingHeader.propTypes = {
  title: PropTypes.string.isRequired,
  right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  storageKey: PropTypes.oneOf(['scenes', 'elements', 'scores']).isRequired,
}

TestingContentSection.propTypes = {
  children: PropTypes.node.isRequired,
}

TestingContent.propTypes = {
  children: PropTypes.node.isRequired,
  noWrapSection: PropTypes.bool,
}

export default TestingControls
